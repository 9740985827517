<template>
  <div class="heroContainer">
    <div class="heroContainer-backgroundWrapper background-wrapper">
      <div class="full-width-background absolute w-full overflow-hidden h-full">
        <video
          id="bgVideo"
          preload="none"
          poster="https://privabo-assets.s3.eu-central-1.amazonaws.com/home-bg-preload-img.png"
          playsinline=""
          autoplay=""
          muted="muted"
          loop=""
          class="full-width-background-video"
        >
          <source v-if="isPageFullLoaded" src="https://privabo-assets.s3.eu-central-1.amazonaws.com/dashboard_futuristic_cm.webm" type="video/webm">
          <source v-if="isPageFullLoaded" src="https://privabo-assets.s3.eu-central-1.amazonaws.com/dashboard_futuristic_cm.mp4" type="video/mp4">
          <source v-if="isPageFullLoaded" src="https://privabo-assets.s3.eu-central-1.amazonaws.com/dashboard_futuristic_cm.mov" type="video/mov">
        </video>
      </div>
    </div>
    <div class="heroContainer-textContainer w-full justify-items-center	info-bg">
      <div class="grid justify-items-center">
        <div>
          <img src="https://privabo-assets.s3.eu-central-1.amazonaws.com/privabo-logo-white.png"
               class="max-h-36 mb-8 mt-24 lg:max-h-96 lg:mb-32"
               alt="PRIVABO.de">
        </div>
        <h1 class="text-white text-4xl lg:text-7xl">Enterprise Software Solutions</h1>
        <p class="text-white text-3xl lg:text-4xl">Business- / Unternehmenssoftware <br> Planung | Entwicklung | Aufbau</p>
      </div>
    </div>
  </div>
  <div class="absolute bottom-0 w-full">
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    Footer
  },
  data () {
    return {
      isPageFullLoaded: false
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.isPageFullLoaded = true
    })
  }
}
</script>
<style scoped>
.background-wrapper .full-width-background {
  background-color: #252525;
  height: 100%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transition: none;
  width: 100%;
  z-index: -1;
}

.heroContainer .heroContainer-backgroundWrapper .full-width-background-video {
  top: -40vh;
  background-color: #2c3e50;
}

.heroContainer .full-width-background video {
  left: 0;
  opacity: .7;
  top: 0;
  transform: none;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.background-wrapper .full-width-background img, .background-wrapper .full-width-background video {
  display: block;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

.info-bg {
  /*background-color: rgb(0, 0, 0); !* Fallback color *!*/
  /*background-color: rgba(0, 0, 0, 0.4); !* Black w/opacity/see-through *!*/
  color: white;
  font-weight: bold;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  text-align: center;
}
@media only screen and (max-height: 500px) {
  .slider-content h1, .slider-content h2, .slider-content h3, .slider-content h4 {
    font-size: 26px !important;
  }

  .slider-content .btn {
    font-size: 14px;
    padding: 0 15px;
    height: 32px;
    line-height: 32px;
  }
}
</style>
