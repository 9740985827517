<template>
  <Header class="pt-24"></Header>
  <section class="section-padding">
    <div class="container">
      <div class="section-header text-left">
        <h3>zugleich Angaben gemäß § 6 TDG, § 10 MDStV</h3>
        <p>Privabo GmbH &amp; Co. KG<br/>
          Keltenstrasse 21<br/>
          86477 Adelsried<br/>
          Deutschland/Germany</p>
        <h3>Kontakt</h3>
        <p>Telefon: 08294 33 899 80<br/>
          E-Mail: contact@privabo.de<br/>
          Internet: <a href="https://privabo.de">https://privabo.de</a>
        </p>
        <h3>Registrierung</h3>
        <p>Privabo GmbH &amp; Co.KG<br/>
          HRA 20 005</p>
        <p>Persönlich haftende Gesellschafterin<br/>
          Privabo Verwaltungs GmbH<br/>
          HRB 34 086<br/>
          jeweils Amtsgericht Augsburg </p>
        <h4>Geschäftsführung:</h4>
        <p>Privabo Verwaltungs GmbH<br/>
          Vertretungsberechtigt: Eugen Heinrich </p>
        <h4>Zulassung / Aufsichtsbehörde</h4>
        <p>Industrie- und Handelskammer Schwaben<br/>
          Stettenstraße 1 + 3<br/>
          86150 Augsburg<br/>
          Tel: +49 821 3162 0<br/>
          Fax: +49 821 3162 323<br/>
          E-Mail: info@schwaben.ihk.de<br/>
        </p>
        <h4>Gewerbeaufsichtsamt Augsburg</h4>
        <p>Morellstraße 30 d<br/>
          86159 Augsburg<br/>
          Tel: +49 821 5709-02 </p>
        <h3>Haftungsausschluss:</h3>
        <h4>Haftung für Inhalte</h4>
        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
          entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
          entfernen.</p>
        <h4>Haftung für Links</h4>
        <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
          Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
          Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
          verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
          waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
          inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
          Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
          von Rechtsverletzungen werden wir derartige Links umgehend
          entfernen. <br><br>
          Free Stock Videos by <a href="http://www.videezy.com">Videezy.com</a>
          <br><br>
        </p>
        <h4>EU-Streitschlichtung</h4>
        <p>Gemäß Verordnung über Online-Streitbeilegung in
          Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
          Online-Streitbeilegungsplattform (OS
          -Plattform) informieren. Verbraucher haben die Möglichkeit, Beschwerden
          an die Online Streitbeilegungsplattform der Europäischen Kommission
          unter <a href="http://ec.europa.eu/odr?tid=321125264">http://ec.europa.eu/odr?tid=321125264</a>
          zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
          unserem Impressum.<br/>
          Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
          verpflichtet sind, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle
          teilzunehmen.</p></div>
    </div>
  </section>
  <Footer></Footer>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'Imprint',
  components: {
    Header, Footer
  }
}
</script>
