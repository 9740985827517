<template>
  <footer>
    <div class="footer-top h-42">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <router-link to="/">Startseite</router-link>
            |
            <router-link to="/impressum">Impressum</router-link>
            |
            <router-link to="/datenschutz">Datenschutz</router-link>
            <p class="text-xl m-auto text-white leading-8">Copyright &copy; {{ new Date().getFullYear() }} Privabo GmbH & Co. KG - All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
<style scoped>
.footer-top {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
