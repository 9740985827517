<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="col-sm-3 col-md-2">
          <div class="logo">
            <a href="/">
              <img class="lazy img-responsive"
                   data-src="https://privabo-assets.s3.eu-central-1.amazonaws.com/logo153_63.jpg"
                   src="https://privabo-assets.s3.eu-central-1.amazonaws.com/logo153_63.jpg"
                   alt="PRIVABO.de">
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'Header'
}
</script>
